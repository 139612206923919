// src/store/services/users.js
import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client';

class Company extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'FeedSingle'

  // Define default properties here
  static instanceDefaults() {
    return {
        name: '',
        url: '',
        query: [{
            name: String,
            code: String,
            priority: {default: 0, type: Number},
        }],
        ftp: {},
        cron: {},
        queryData: {
            total: null,
            fields: [],
            data: {
                before: [],
                after: [],
            }
        }
    };
  }
}
const servicePath = 'companies';
const servicePlugin = makeServicePlugin({
  Model: Company,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
