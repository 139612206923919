<template>
  <div>
      <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',

  components: {
  },
  mounted() {
    document.title = 'Boosmart XML'
  },

  data: () => ({
       
  }),
};
</script>

<style lang="scss">
    body {
        font-family: 'Roboto', sans-serif;
    }
</style>
