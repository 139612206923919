// src/store/services/users.js
import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client';
export class Feed extends BaseModel {
    constructor(data, options) {
        super(data, options);
    }
    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = 'Feed';
    // Define default properties here
    static instanceDefaults() {
        return {};
    }
}
const servicePath = 'feed';
const servicePlugin = makeServicePlugin({
    Model: Feed,
    service: feathersClient.service(servicePath),
    servicePath,
});
// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
});
export default servicePlugin;
